<template>
  <div class="login" v-if="!registerView">
    <h1 class="title">Connexion</h1>
    <form @submit="login">
        <input class="input" type="email" placeholder="exemple@gmail.fr" @focus="$event.target.select()" v-model="this.email"/>
        <input class="input" type="password" placeholder="*********" @focus="$event.target.select()" v-model="this.password"/>
      <button class="button is-primary" type="submit" :disabled="!this.email || !this.password">Connexion</button>
    </form>
    <p class="switch-view">Pas encore de compte ?
      <button class="switch-btn" v-on:click="switchView">Inscription ici</button>
    </p>
  </div>
  <div class="login" v-if="registerView">
    <h1 class="title">Inscription</h1>
    <form @submit="register" v-if="registerView">
        <input class="input" type="email" placeholder="exemple@gmail.fr" @focus="$event.target.select()" v-model="this.email"/>
        <input class="input" type="password" placeholder="*********" @focus="$event.target.select()" v-model="this.password"/>
      <button class="button is-primary" type="submit" :disabled="!this.email || !this.password">Inscription</button>
    </form>
    <p class="switch-view">Déjà un compte ?
      <button class="switch-btn" v-on:click="switchView">Connexion ici</button>
    </p>
  </div>
</template>

<script>
import store from "../store";

export default {
  data() {
    return {
      registerView: false,
      email: null,
      password: null,
    }
  },
  methods: {
    login() {
      // authService.login(this.email, this.password);
      store.dispatch('login', {
        email: this.email,
        password: this.password
      });
    },
    register() {
      // authService.register(this.email, this.password);
      store.dispatch('register', {
        email: this.email,
        password: this.password
      });
    },
    switchView() {
      this.registerView = !this.registerView;
    },
    getSwitchView() {
      return this.switchView()
    }
  }
}
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  padding: 20%;
}

.switch-view {
  margin: 25px;
  color: grey;
}

.switch-btn {
  font-size: 1em;
  text-align: left;
  color: darkblue;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

input {
  margin: 5px;
  text-align: center;
}

</style>